import React, { useState, useEffect } from 'react';
import '../styles/global.scss';
import { RecoilRoot } from 'recoil';
import { Helmet } from 'react-helmet';
import scrollTo from 'gatsby-plugin-smoothscroll';
import $ from 'jquery';
import '../pagepiling/jquery.pagepiling';
import '../pagepiling/jquery.pagepiling.css';
import '../pagepiling/index.css';
import { useMediaQuery } from 'react-responsive';
import { CSButton, MainSection1 } from '../components/main/PC/main-section-1';
import { MainSection2 } from '../components/main/PC/main-section-2';
import { MainSection3 } from '../components/main/PC/main-section-3';
import { MainSection4 } from '../components/main/PC/main-section-4';
import { Mobile, PC } from '../components/layout/mediaQuery';
import { MainSection2Mobile } from '../components/main/Mobile/main-section-2';
import MainSection4Mobile from '../components/main/Mobile/main-section-4';
import { MainSection3Mobile } from '../components/main/Mobile/main-section-3';
import { MainSection1Mobile } from '../components/main/Mobile/main-section-1';
import { Header } from '../components/layout/PC/header';
import { HeaderMobile } from '../components/layout/Mobile/header';
import Footer from '../components/layout/PC/footer';
import 'aos/dist/aos.css';
import FooterMobile from '../components/layout/Mobile/footer';
// import modalImg from '../assets/images/product/event-modal.png';
// import SvgClose from '../assets/images/icon/close';

// function Modal(props: { setIsModalOpen: any; }) {
//   const { setIsModalOpen } = props;
//   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
//   return (
//     <div style={{
//       background: '#35414CCC',
//       position: 'fixed',
//       zIndex: '1000',
//       width: '100vw',
//       height: '100vh',
//       overflow: 'hidden',
//     }}
//     >
//       <div style={{
//         background: 'var(--color-light)',
//         width: isMobile ? '80%' : 'calc(0.052vw * 500)',
//         height: isMobile ? 'calc(0.266vw * 375)' : 'calc(0.052vw * 626)',
//         position: 'absolute',
//         left: '50%',
//         top: '50%',
//         transform: 'translate(-50%, -50%)',
//         borderRadius: isMobile ? 'calc(0.266vw * 12)' : 'calc(0.052vw * 20)',
//       }}
//       >
//         <a
//           href="https://mealion.notion.site/c09e730fb03f4f21868901c475e27d08?utm_source=web&utm_medium=pop-up"
//           target="_blank"
//           rel="noopener noreferrer"
//           style={{ textDecoration: 'none' }}
//         >
//           <img src={modalImg} width="100%" alt="event-modal" />
//         </a>
//         <button
//           type="button"
//           style={{
//             color: 'var(--color-black-text)',
//             fontSize: isMobile ? 'calc(0.266vw * 14)' : 'calc(0.052vw * 20)',
//             letterSpacing: isMobile ? 'calc(0.266vw * -0.5)' : 'calc(0.052vw * -0.5)',
//             border: 'none',
//             fontWeight: '700',
//             float: 'right',
//             display: 'flex',
//             flexDirection: 'row',
//             justifyContent: 'center',
//             alignItems: 'center',
//             marginTop: isMobile ? 'calc(0.266vw * 5)' : 'calc(0.052vw * 10)',
//             marginRight: isMobile ? 'calc(0.266vw * 14)' : 'calc(0.052vw * 20)',
//             background: 'transparent',
//           }}
//           onClick={() => setIsModalOpen(false)}
//         >
//           닫기
//           <div style={{
//             width: isMobile ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 24)',
//             height: isMobile ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 24)',
//             marginLeft: isMobile ? 'calc(0.266vw * 2)' : 'calc(0.052vw * 3)',
//             marginTop: isMobile ? 'calc(0.266vw * 2)' : 'calc(0.052vw * 3)',
//           }}
//           >
//             <SvgClose width="100%" />
//           </div>
//         </button>
//       </div>
//     </div>
//   );
// }
function Index() {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [goToApplication, setGoToApplication] = useState(false);
  const [goToSlideMobile, setGoToSlideMobile] = useState(false);
  const [goToTheTop, setGoToTheTop] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      document.body.className = 'pc';
      $(() => {
        ($('#pagepiling') as any).pagepiling({
          afterLoad(_anchorLink: any, index: number) {
            if (index === 4) {
              setShowIcon(true);
            } else {
              setShowIcon(false);
            }
          },
        });
      });
    } else {
      document.body.className = 'mobile';
    }
  }, []);

  useEffect(() => {
    if (goToApplication) {
      ($ as any).fn.pagepiling.moveTo(4);
    }
  }, [goToApplication]);

  useEffect(() => {
    if (goToTheTop) {
      ($ as any).fn.pagepiling.moveTo(1);
    }
  }, [goToTheTop]);

  useEffect(() => {
    if (goToSlideMobile) {
      scrollTo('#section4');
    }

    setGoToSlideMobile(false);
  }, [goToSlideMobile]);

  // const [isModalOpen, setIsModalOpen] = useState(true);

  return (
    <RecoilRoot>
      <Helmet>
        <title>밀리언</title>
        <meta name="description" content="오늘의 맛있는 발견, 밀리언" data-react-helmet="true" />
        <meta property="og:title" content="밀리언" />
        <meta property="og:description" content="오늘의 맛있는 발견, 밀리언" />
        <meta property="og:url" content="https://mealion.kr" />
        <meta property="og:image" content="https://mealion.kr/static/thumbnail_logo-aab5767b1abdaabbb52dbbb843d601a0.png" />
        <meta name="twitter:url" content="https://mealion.kr" />
        <meta name="twitter:title" content="밀리언" />
        <meta name="twitter:description" content="오늘의 맛있는 발견, 밀리언" />
        <meta name="twitter:image" content="https://mealion.kr/static/thumbnail_logo-aab5767b1abdaabbb52dbbb843d601a0.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <PC>
        <Header main creatorLastPage={false} setGoToTheTop={setGoToTheTop} />
        <div id="pagepiling">
          <CSButton />
          <div className="section"><MainSection1 setGoToApplication={setGoToApplication} /></div>
          <div className="section"><MainSection2 /></div>
          <div className="section" id="mainsection3"><MainSection3 /></div>
          <div className="section pp-scrollable">
            <MainSection4 icon={showIcon} />
            <Footer creatorLastPage={false} />
          </div>
        </div>
      </PC>
      <Mobile>
        <HeaderMobile main />
        <MainSection1Mobile setGoToSlideMobile={setGoToSlideMobile} />
        <MainSection2Mobile />
        <MainSection3Mobile />
        <div id="section4">
          <MainSection4Mobile />
        </div>
        <FooterMobile creatorLastPage={false} />
      </Mobile>
    </RecoilRoot>
  );
}

export default Index;
