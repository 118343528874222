import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Sub, Title, Wrapper } from './main-section-1';
import foods from '../../../assets/images/product/foods-line.png';

export function MainSection2Mobile() {
  const IconTransition = {
    repeat: Infinity,
    duration: 15,
    ease: [0, 0, 0, 0],
  };
  const images = {
    position: 'absolute',
    display: 'inline-block',
    top: '0',
    left: '0',
    height: '100%',
  };
  return (
    <SWrapper>
      <SSub>
        넘쳐나는 맛집 콘텐츠,
        <br />
        그 속에서 갈팡질팡했던 순간들
        <br />
        <br />
        <SSub style={{ fontWeight: '700' }}>
          밀리언에서 나만을 위한 맛집을 발견해요
        </SSub>
      </SSub>
      <RowFlex>
        <Slide>
          <motion.div animate={{ x: '-50%' }} transition={IconTransition} style={images}>
            <img src={foods} alt="foods" height="100%" />
          </motion.div>
        </Slide>
      </RowFlex>
    </SWrapper>
  );
}
const SWrapper = styled(Wrapper)`
padding-top: calc(0.266vw * 116);
padding-bottom: calc(0.266vw * 150);
`;

export const SSub = styled(Sub)`
  font-size: calc(0.266vw * 18);
  line-height: calc(0.266vw * 24);
  margin: 0;
`;

export const STitle = styled(Title)`
  font-size: calc(0.266vw * 20);
  line-height: calc(0.266vw * 30);
  color: var(--color-darker-border);
  
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: calc(0.266vw * 36);
`;
const Slide = styled.div`
  position: relative;
  height: calc(0.266vw * 148);
  width: 100vw;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
`;
