import React, { ChangeEvent, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { Wrapper } from './main-section-1';
import { MobileTitle, MobileSub } from '../../creator/Mobile/creator-section-2';
import wave from '../../../assets/images/illust/wave-mobile.png';
import {
  phoneNumberAutoFormat, Button
} from '../PC/main-section-4';
import {
  APP_STORE_URL, CTA_ULR_MOBILE, GOOGLE_PLAY_URL,
} from '../../../constants/urls';
import china from '../../../assets/images/illust/china.png';
import visitsix from '../../../assets/images/illust/visitsix.png';
import cake from '../../../assets/images/illust/cake.png';
import japan from '../../../assets/images/illust/japan.png';
import { ColumFlex } from '../PC/main-section-1';
import googleplayLogo from '../../../assets/images/icon/google-play-icon.png';
import appstoreLogo from '../../../assets/images/icon/app-store-icon.png';

interface IsAgreed {
  isAgreed: boolean,
}

const ErrorToast: CSSProperties = {
  top: 'calc(0.266vw * 78)',
  textAlign: 'center',
  zIndex: 999,
  position: 'relative',
  background: '#FFEBED',
  color: '#F04252',
  fontSize: 'calc(0.266vw * 14)',
  padding: 'calc(0.266vw * 6) 0',
  lineHeight: 'calc(0.266vw * 21)',
  letterSpacing: 'calc(0.266vw * -0.33)',
  fontWeight: '700',
  width: 'calc(0.266vw * 312)',
  boxShadow: '0 calc(0.266vw * 4) calc(0.266vw * 20) 0 #00000040',
  border: 'calc(0.266vw * 2) solid #F04252',
  borderRadius: 'calc(0.266vw * 8)',
};

const SuccessToast = {
  ...ErrorToast,
  background: '#E5F2FF',
  color: 'var(--color-new-primary)',
  height: 'calc(0.266vw * 55)',
  border: 'calc(0.266vw * 2) solid var(--color-new-primary)',
};

const IconStyle : CSSProperties = {
  position: 'absolute',
  width: 'calc(0.266vw * 40)',
  height: 'calc(0.266vw * 40)',
  zIndex: -1,
};

export default function MainSection4Mobile() {
  const [isAgreed, setIsAgreed] = useState(false);
  const onClick = () => {
    setIsAgreed(!isAgreed);
  };

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [focus, setFocus] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const targetValue = phoneNumberAutoFormat(e.target.value);
    setPhoneNumber(targetValue);
  };

  async function onSendNumber(number: string) {
    try {
      const response = await axios.post('https://r.mealion.kr', {
        phone: number,
      }, {
        validateStatus() {
          return true;
        },
      });
      if (response.status >= 200 && response.status < 300) {
        setSubmitted(true);
        toast.success('앱 오픈 알림 받기 신청이 완료되었습니다.', {
          duration: 2000,
        });
      } else if (response.status === 409) {
        toast.error('이전에 신청한 기록이 있어요.', {
          duration: 1500,
        });
      } else if (response.status === 429) {
        toast.error('너무 많이 시도했습니다. 잠시 후에 다시 시도해보세요.', {
          duration: 1500,
        });
      } else {
        toast.error('오류가 발생했습니다. 고객센터에 문의하세요.', {
          duration: 1500,
        });
      }
    } catch (e) {
      toast.error('오류가 발생했습니다. 고객센터에 문의하세요.', {
        duration: 1500,
      });
    }
  }
  const onSubmit = () => {
    const number = phoneNumber.replace(/ /gi, '-');

    if (!isAgreed) {
      toast.error('약관에 동의해주세요. 앱 오픈 알림 메세지 전송을 위해 이용됩니다 :)', {
        duration: 1500,
      });
    } else if (phoneNumber.length < 13) {
      toast.loading('전화번호를 올바르게 입력해주세요.', {
        duration: 1500,
      }); // 전화번호 형식 검사
    } else {
      onSendNumber(number);
    }
  };

  return (
    <SWrapper>
      <STitle>
        맛있는 모든 순간을
        <br />
        밀리언과 함께
      </STitle>
      <SSub>
        “오늘 뭐 먹지?” 고민의 시작과 해결을 함께 해요.
      </SSub>
      <ColumFlex>
        <Link to={GOOGLE_PLAY_URL} target="_blank" style={{ textDecoration: 'none', marginBottom: 'calc(0.266vw * 20)' }}>
          <DownloadButton>
            <DownloadIcon src={googleplayLogo} />
            Google Play
          </DownloadButton>
        </Link>
        <Link to={APP_STORE_URL} target="_blank" style={{ textDecoration: 'none' }}>
          <DownloadButton>
            <DownloadIcon src={appstoreLogo} />
            App Store
          </DownloadButton>
        </Link>

      </ColumFlex>

      <Toaster
        gutter={8}
        toastOptions={{
          success: {
            icon: null,
            className: '',
            style: SuccessToast,
          },
          error: {
            icon: null,
            className: '',
            style: ErrorToast,
          },
          loading: {
            icon: null,
            className: '',
            style: {
              ...ErrorToast,
              height: 'calc(0.266vw * 55)',
            },
          },
        }}
        containerStyle={{
          padding: 0,
          margin: 0,
        }}
      >
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              ...t.style,
              zIndex: '999',
            }}
          />
        )}
      </Toaster>

      <motion.div
        style={{
          ...IconStyle,
          left: 'calc(0.266vw * 24)',
          marginTop: 'calc(0.266vw * 20)',
        }}
      >
        <img src={visitsix} alt="meat" width="100%" />
      </motion.div>
      <motion.div
        // animate={IconAnimation}
        // transition={IconTransition}
        style={{
          ...IconStyle,
          left: 'calc(0.266vw * 119)',
          marginTop: 'calc(0.266vw * -50)',

        }}
      >
        <img src={china} alt="jajangmyeon" width="100%" />
      </motion.div>

      <motion.div
        style={{
          ...IconStyle,
          right: 'calc(0.266vw * 118)',
          marginTop: 'calc(0.266vw * 15)',
        }}
      >
        <img src={cake} alt="cake" width="100%" />
      </motion.div>

      <motion.div
        style={{
          ...IconStyle,
          right: 'calc(0.266vw * 20)',
          marginTop: 'calc(0.266vw * -40)',
        }}
      >
        <img src={japan} alt="sushi" width="100%" />
      </motion.div>
      <Wave>
        <WhiteTitle>
          밀리언 놀이방
        </WhiteTitle>
        <WhiteSub>
          메뉴추천부터 재밌는 심리테스트까지
          <br />
          기다리시는 동안 즐겨보세요 🔮
        </WhiteSub>
        <a href={CTA_ULR_MOBILE} target="_blank" rel="noopener noreferrer">
          <WhiteButton>
            놀러가기
          </WhiteButton>
        </a>
      </Wave>
    </SWrapper>
  );
}

const SWrapper = styled(Wrapper)`
  padding-top: calc(0.266vw * 76);
  position: relative;
  z-index: 1;
`;
const STitle = styled(MobileTitle)`
  line-height: calc(0.266vw * 30);
  margin-bottom: calc(0.266vw * 12);

`;
const SButton = styled(Button)`
  width: calc(0.266vw * 18);
  height: calc(0.266vw * 18);
`;

const SSub = styled(MobileSub)`
  line-height: calc(0.266vw * 20);
  margin-bottom: calc(0.266vw * 46);
`;
const PhoneInput = styled.input`
  background: var(--color-gray-input);
  border-radius: calc(0.266vw * 8);
  border: none;
  height: calc(0.266vw * 40);
  width: 80%;
  color: var(--color-black-text);
  font-size: calc(0.266vw * 16);
  line-height: calc(0.266vw * 16);
  font-weight: 500;
  letter-spacing: calc(0.266vw * -0.33);
  text-align: center;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: var(--color-light-gray-text);
    letter-spacing: calc(0.266vw * -0.33);
    font-size: calc(0.266vw * 16);
    line-height: calc(0.266vw * 16);
    font-weight: 500;
    text-align: center;
  }
  :-ms-input-placeholder {
    color: var(--color-light-gray-text);
    letter-spacing: calc(0.266vw * -0.33);
    font-size: calc(0.266vw * 16);
    line-height: calc(0.266vw * 16);
    font-weight: 500;
    text-align: center;
  }
  &:focus {
    background: var(--color-light);
    border: 2px solid var(--color-new-primary);
  }
`;

const SubmitButton = styled.button`
  height: calc(0.266vw * 40);
  width: 80%;
  background: var(--color-new-primary);
  text-align: center;
  font-weight: 700;
  font-size: calc(0.266vw * 16);
  line-height: calc(0.266vw * 16);
  letter-spacing: -0.5%;
  color: var(--color-skyblue);
  border: none;
  margin: 0;
  border-radius: calc(0.266vw * 8);
  margin-bottom: calc(0.266vw * 15);

  &:disabled {
    background: var(--color-darker-border);
    color: var(--color-light);
  }
`;

const DownloadButton = styled.button`
    background-color: var(--color-black-text);
  border-radius: calc(0.266vw * 5);
  width: calc(0.266vw * 155);
  height: calc(0.266vw * 44);
  font-size:calc(0.266vw * 16);
  line-height: 100%;
  letter-spacing: calc(0.266vw * -0.5);
  font-weight: 700;
  border: none;
  color: var(--color-light);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DownloadIcon = styled.img`
  width: calc(0.266vw * 24);
  height: calc(0.266vw * 24);
`;

const PrivacyConsent = styled(MobileSub)<IsAgreed>`
  font-weight: 500;
  color: ${(props) => (props.isAgreed ? 'var(--color-new-primary)' : '#D0D5DA')};
  font-size: calc(0.266vw * 14);
  line-height: calc(0.266vw * 15);
  margin-left: calc(0.266vw * 4);
`;

const Underline = styled.strong`
  text-decoration: underline;
  font-weight: 500;
`;

const Wave = styled.div`
  margin-top: calc(0.266vw * 80);
    background-image: url(${wave});
    width: 100vw;
    height: calc(0.266vw * 370);
    padding-top: 27%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const WhiteTitle = styled(STitle)`
  margin-bottom: calc(0.266vw * 14);
  color: var(--color-light);
`;
const WhiteSub = styled(SSub)`
  color: var(--color-light-gray-text);
  line-height: 150%;
  margin-bottom: calc(0.266vw * 24);
`;

const WhiteButton = styled(SubmitButton)`
  background: #FFFFFF1A;
  border: calc(0.266vw * 1.5) solid var(--color-skyblue);
  line-height: calc(0.266vw * 20);
  border-radius: calc(0.266vw * 5);
  color: var(--color-light);
  display: inline-block;
  width: calc(0.266vw * 95);
  margin: 0;  
  white-space: nowrap;
`;
const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  margin-top: calc(0.266vw * 34);
  margin-bottom: calc(0.266vw * 12);
`;
