import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {
  Slider, Slide, Dot, CarouselContext, CarouselProvider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'aos/dist/aos.css';
import '../../../styles/carousel-mobile.css';
import { STitle, SSub } from './main-section-2';
import { Wrapper } from './main-section-1';
import mockup1 from '../../../assets/images/product/mockup-home.png';
import mockup2 from '../../../assets/images/product/mockup-search.png';
import mockup3 from '../../../assets/images/product/mockup-scrap.png';
import mockup4 from '../../../assets/images/product/mockup-schedule.png';

interface isclickedProp {
  isclicked: string,
}

const Buttonlist = [
  {
    id: 0,
    name: '홈',
    isclicked: true,
  },
  {
    id: 1,
    name: '추천',
    isclicked: false,
  },
  {
    id: 2,
    name: '스크랩',
    isclicked: false,
  },
  {
    id: 3,
    name: '약속',
    isclicked: false,
  },
];

function Carousel() {
  const buttonList = Buttonlist;
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <div>
      <RowFlex>
        <Functions>
          {
          buttonList.map((button) => (
            <Function
              slide={button.id}
              key={button.id}
              isclicked={(button.id === currentSlide).toString()}
            >
              <>
                {button.name}
                <Bar isclicked={(button.id === currentSlide).toString()} />
              </>
            </Function>
          ))
        }
        </Functions>
      </RowFlex>
      <Slider>
        <Slide index={0}>
          <ColumnFlex>
            <TitleDefault>
              오늘 뭐 먹지?
            </TitleDefault>
            <SubDefault>
              고민이 될 땐, 밀리언으로 찾아오세요.
              <br />
              메뉴를 결정하는 순간이
              <Strong> 늘 설렘으로 가득하게!</Strong>
            </SubDefault>
            <ImgContainer>
              <img src={mockup1} alt="mockup1" width="100%" />
            </ImgContainer>
          </ColumnFlex>
        </Slide>
        <Slide index={1}>
          <ColumnFlex>
            <TitleDefault>
              믿을 수 있는 &apos;콘텐츠&apos;
            </TitleDefault>
            <SubDefault>
              이제는 크리에이터들이
              <Strong> 직접 방문하고</Strong>
              <br />
              <Strong>작성한 콘텐츠를 구경</Strong>
              해보세요.
            </SubDefault>
            <ImgContainer>
              <img src={mockup2} alt="mockup2" width="100%" />
            </ImgContainer>
          </ColumnFlex>
        </Slide>
        <Slide index={2}>
          <ColumnFlex>
            <TitleDefault>
              마음에 드는 맛집은 &apos;스크랩&apos;
            </TitleDefault>
            <SubDefault>
              더 이상 스크린 샷도 지도 앱도 필요치 않아요.
              <br />
              <Strong>태그 기반으로 알아서 정리되고 위치까지 한눈에!</Strong>
            </SubDefault>
            <ImgContainer>
              <img src={mockup3} alt="mockup3" width="100%" />
            </ImgContainer>
          </ColumnFlex>
        </Slide>
        <Slide index={3}>
          <ColumnFlex>
            <div>
              <TitleDefault>
                함께하는 즐거움 &apos;약속&apos;
              </TitleDefault>
              <SubDefault>
                &apos;함께&apos;는 즐겁지만 함께하는 &apos;과정&apos;에 있던 번거로움
                <br />
                밀리언에서는
                <Strong> 공유부터 채팅까지 한 번에 다 되니까</Strong>
              </SubDefault>
              <ImgContainer>
                <img src={mockup4} alt="mockup4" width="100%" />
              </ImgContainer>
            </div>
          </ColumnFlex>
        </Slide>
      </Slider>
    </div>
  );
}

export function MainSection3Mobile() {
  return (
    <SWrapper>
      <CarouselProvider
        naturalSlideHeight={500}
        naturalSlideWidth={525}
        totalSlides={4}
        visibleSlides={1}
      >
        <Carousel />
      </CarouselProvider>
    </SWrapper>
  );
}

export const SWrapper = styled(Wrapper)`
  background: var(--color-skyblue2);
  height: calc(0.266vw * 642);
  padding: 0;
  padding-top: calc(0.266vw * 152);
  overflow: hidden;
`;

export const TitleDefault = styled(STitle)`
  line-height: calc(0.266vw * 20);
  color: var(--color-black-text);
  letter-spacing: calc(0.266vw * -0.5);
  margin-bottom: calc(0.266vw * 16);
`;

export const SubDefault = styled(SSub)`
  font-size: calc(0.266vw * 15);
  line-height:calc(0.266vw * 22);
  letter-spacing: calc(0.266vw * -0.5);
`;

const Strong = styled.strong`
  font-weight: 700;
  letter-spacing: calc(0.266vw * -0.5);
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: calc(0.266vw * 30);
  position: relative;
  z-index: 1;
`;
const Functions = styled.div`
  width: 55%;
  display: flex;
  justify-content: space-between;
`;

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Function = styled(Dot)<isclickedProp>`
  color: ${(props) => (props.isclicked === 'true' ? 'var(--color-new-primary)' : 'var(--color-light-gray-text)')};
  font-size: calc(0.266vw * 18);
  line-height:calc(0.266vw * 18);
  font-weight: 700;
  letter-spacing: -0.5%;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
`;

const Bar = styled.div<isclickedProp>`
  background: ${(props) => (props.isclicked === 'true' ? 'var(--color-new-primary)' : 'transparent')};
  height: calc(0.266vw * 4);
  border-radius: calc(0.266vw * 16);
  margin-top: calc(0.266vw * 7);
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -13%;
`;
