import React, { useContext, useEffect, useState } from 'react';
import {
  CarouselProvider, Slider, Slide, Dot, CarouselContext, WithStore,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'aos/dist/aos.css';
import '../../../styles/carousel.css';
import styled from 'styled-components';
import '../../../styles/slick.scss';
import '../../../styles/slick-theme.css';
import '../../../styles/slick.css';
import mockup1 from '../../../assets/images/product/mockup-home.png';
import mockup2 from '../../../assets/images/product/mockup-search.png';
import mockup3 from '../../../assets/images/product/mockup-scrap.png';
import mockup4 from '../../../assets/images/product/mockup-schedule.png';

export const Buttonlist = [
  {
    id: 0,
    name: '홈',
  },
  {
    id: 1,
    name: '추천',
  },
  {
    id: 2,
    name: '스크랩',
  },
  {
    id: 3,
    name: '약속',
  },
];

interface isClickedProp {
  isclicked: string,
}
interface StrongProp {
  strong: boolean,
}

function Carousel() {
  const buttonList = Buttonlist;
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <>
      <RowFlex>
        <Functions>
          {
          buttonList.map((button) => (
            <Function
              slide={button.id}
              key={button.id}
              isclicked={(button.id === currentSlide).toString()}
            >
              <>
                {button.name}
                <Bar isclicked={(button.id === currentSlide).toString()} />
              </>
            </Function>
          ))
        }
        </Functions>
      </RowFlex>
      <Slider>
        <Slide index={0}>
          <RoundSquare>
            <Row>
              <ImgContainer>
                <Mockup src={mockup1} alt="mockup1" />
              </ImgContainer>
              <ColumnFlex>
                <TextContainer>
                  <H2>
                    오늘 뭐 먹지?
                  </H2>
                  <H3 strong={false}>
                    고민이 될 땐,
                    <br />
                    밀리언으로 찾아오세요.
                    <br />
                    <br />
                    메뉴를 결정하는 순간이
                    <br />
                    <H3 strong>늘 설렘으로 가득하게!</H3>
                  </H3>
                </TextContainer>
              </ColumnFlex>
            </Row>
          </RoundSquare>
        </Slide>
        <Slide index={1}>
          <RoundSquare>
            <Row>
              <ImgContainer>
                <Mockup src={mockup2} alt="mockup2" />
              </ImgContainer>
              <ColumnFlex>
                <TextContainer>
                  <H2>
                    믿을 수 있는 ‘콘텐츠’
                  </H2>
                  <H3 strong={false}>
                    광고는 아닐까, 믿을 수 있을까?
                    <br />
                    걱정됐던 순간들
                    <br />
                    <br />
                    이제는 크리에이터들이
                    <H3 strong> 직접 방문하고</H3>
                    <br />
                    <H3 strong>작성한 콘텐츠를 구경</H3>
                    해보세요.
                  </H3>
                </TextContainer>
              </ColumnFlex>
            </Row>
          </RoundSquare>
        </Slide>

        <Slide index={2}>
          <RoundSquare>
            <Row>
              <ImgContainer>
                <Mockup src={mockup3} alt="mockup3" />
              </ImgContainer>
              <ColumnFlex>
                <TextContainer>
                  <H2>
                    마음에 드는 맛집은 ‘스크랩’
                  </H2>
                  <H3 strong={false}>
                    더 이상 스크린 샷도 지도 앱도 필요치 않아요.
                    <br />
                    <br />
                    <H3 strong>마음에 들면 스크랩만 누르면 되니까</H3>
                    <br />
                    태그 기반으로 알아서 정리되고 위치까지
                    <br />
                    한눈에!
                  </H3>
                </TextContainer>
              </ColumnFlex>
            </Row>
          </RoundSquare>
        </Slide>
        <Slide index={3}>
          <RoundSquare>
            <Row>
              <ImgContainer>
                <Mockup src={mockup4} alt="mockup4" />
              </ImgContainer>
              <ColumnFlex>
                <TextContainer>
                  <H2>
                    함께하는 즐거움 ‘약속하기’
                  </H2>
                  <H3 strong={false}>
                    약속 잡으려면 링크를 몇 번이나 공유해야 하는지,
                    <br />
                    ‘함께’는 즐겁지만 함께하는 ‘과정’에 있던 번거로움.
                    <br />
                    <br />
                    더 이상 번거롭게 약속 잡을 필요 없어요!
                    <br />
                    <H3 strong>공유부터 채팅까지 한 번에 다 되니까</H3>
                  </H3>
                </TextContainer>
              </ColumnFlex>
            </Row>
          </RoundSquare>
        </Slide>
      </Slider>
    </>
  );
}

export function MainSection3() {
  return (
    <Wrapper>
      <CarouselProvider
        naturalSlideHeight={100}
        naturalSlideWidth={125}
        totalSlides={4}
        visibleSlides={1}
        isPlaying
      >
        <Carousel />
      </CarouselProvider>
    </Wrapper>
  );
}
export default WithStore(Carousel, (state) => ({
  currentSlide: state.currentSlide,
}));

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding-top: 130px;
  @media only screen and (min-height: 850px) {
    padding-top: 150px;
  }
  background-color: var(--color-light);
  overflow: hidden;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Functions = styled.div`
  width: 330px;
  display: flex;
  justify-content: space-between;
`;
export const Bar = styled.div<isClickedProp>`
  background: ${(props) => (props.isclicked === 'true' ? 'var(--color-new-primary)' : 'transparent')};
  height: 5px;
  border-radius: 16px;
  margin-top: 14px;
`;

const ColumnFlex = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Function = styled(Dot)<isClickedProp>`
  color: ${(props) => (props.isclicked === 'true' ? 'var(--color-new-primary)' : 'var(--color-light-gray-text)')};
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.5%;
  line-height: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  @media screen and (max-width: 1440px), screen and (max-height: 850px) {
    font-size: 28px;
  }
`;

const Mockup = styled.img`
  width: 540px;
  @media only screen and (min-height: 850px) {
    width: 657px;
  }
`;

const RoundSquare = styled.div`
  width: 900px !important;
  margin: 0 !important;
  height: 350px;
  border-radius: 200px;
  background: var(--color-skyblue2);
  @media only screen and (min-height: 850px) {
    width: 1100px !important;
    height: 400px;
  }
`;

const H2 = styled.span`
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.5px;
  font-weight: 700;
  color: var(--color-black-text);
  margin-bottom: 32px;
  @media screen and (max-width: 1440px), screen and (max-height: 850px) {
    font-size: 28px;
  }
`;

const H3 = styled(H2)<StrongProp>`
  font-size: 20px;
  line-height: 32px;
  font-weight: ${(props) => (props.strong ? '700' : '500')};
  color: ${(props) => (props.strong ? 'var(--color-black-text)' : 'var(--color-gray-text)')};
  margin: 0;
  white-space: pre-wrap;
  @media screen and (max-width: 1440px), screen and (max-height: 850px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

const ImgContainer = styled(ColumnFlex)`
  align-items: center;
  margin-top: -220px;
  @media screen and (max-width: 1440px), screen and (max-height: 850px) {
    margin-top: -180px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 85px;
  margin-left: -80px;
  @media screen and (max-width: 1440px), screen and (max-height: 850px) {
    width: -65px;
    margin-top: 65px;
    margin-left: -70px;
  }
`;
